export const pickUpSpecials = [
  {
    name: 'Any 9" Classic Hoagie',
    description: '',
    price: '$8.99',
    image: '',
  },
  {
    name: 'Any 9" Gourmet Hoagie',
    description: '',
    price: '$9.99',
  },
  {
    name: 'Any 15" Classic Hoagie',
    description: '',
    price: '$14.99',
    image: '',
  },
  {
    name: 'Any 15" Gourmet Hoagie',
    description: '',
    price: '$16.99',
    image: '',
  },
  {
    name: 'Large Plain Pizza',
    description: '',
    price: '$13.99+',
    image: '',
  },
  {
    name: 'X-Large Plain Pizza',
    description: '',
    price: '$15.99+',
    image: '',
  },
];

export const specials = [
  {
    name: 'Two Giant Cheese Pizzas',
    description: '',
    price: '$40.99+',
    image: '',
  },
  {
    name: '1 Topping Special ',
    description: 'Large, X-Large, or giant one topping pizza.',
    price: '$18.99+',
    image: '',
  },
  {
    name: 'Pair of Pizzas  ',
    description: '2 medium, large, X-Large, or giant pizzas.',
    price: '$26.99+',
    image: '',
  },
  {
    name: 'X-Large Cheese Pizza, 15" Hoagie, Bread Sticks, & 2 Liter Soda  ',
    description: '',
    price: '$42.99+',
    image: '',
  },
  {
    name: 'Two Classic Hoagie Special  ',
    description: 'Any two 15" hoagies.',
    price: '$30.99',
    image: '',
  },
  {
    name: 'Any Two 15" Gourmet Hoagies  ',
    description: '',
    price: '$34.99',
    image: '',
  },
  {
    name: '2 Gyro Special  ',
    description: 'Mix or match chicken, lamb, or steak gyro and 1 order of fries.',
    price: '$24.99',
    image: '',
  },
  {
    name: 'Large Cheese Pizza with 15" Hoagie  ',
    description: '',
    price: '$30.99+',
    image: '',
  },
  {
    name: 'Large Cheese Pizza with Dozen Wings  ',
    description: 'Large Cheese Pizza with Dozen Wings ',
    price: '$31.99',
    image: '',
  },
  {
    name: 'Dozen Wings & Any 15" Hoagie   ',
    description: 'Dozen wings and any 15" hoagie',
    price: '$31.99+',
    image: '',
  },
  {
    name: 'X-Large Cheese Pizza with Dozen Wings, Bread Sticks & 2 Liter Soda  ',
    description: ' ',
    price: '$44.99+',
    image: '',
  },
];

export const appetizers = [
  { name: 'Garlic Bread ', description: '', price: '$1.99', image: '' },
  { name: 'Garlic Bread with Cheese ', description: '', price: '$2.99', image: '' },
  { name: 'Mozzarella Sticks (4) ', description: 'Served with marinara.', price: '$7.99', image: '' },
  { name: 'Breaded Mushrooms ', description: 'Served with marinara.', price: '$7.99', image: '' },
  { name: 'Breaded Zucchini ', description: 'Served with marinara.', price: '$7.99', image: '' },
  { name: 'Chicken Tenders (5) ', description: 'Served with marinara.', price: '$10.99', image: '' },
  { name: 'Popcorn Chicken ', description: '', price: '$8.99', image: '' },
  { name: 'Crab Cake Bites ', description: '12 - 14 pieces served with tartar sauce.', price: '$8.99', image: '' },
  { name: 'Jalapeño Poppers (5) ', description: 'Served with ranch.', price: '$7.99', image: '' },
  { name: 'Onion Rings ', description: '', price: '$7.99', image: '' },
  { name: 'Pizza Boat ', description: '', price: '$7.99', image: '' },
  { name: 'French Fries ', description: '', price: '$5.99+', image: '' },
  { name: 'Buffalo Fries ', description: '', price: '$5.99+', image: '' },
  { name: 'French Fries with Melted Provolone Cheese ', description: '', price: '$8.99', image: '' },
  { name: 'French Fries with Nacho Cheese ', description: '', price: '$8.99', image: '' },
  { name: 'French Fries with Melted Provolone & Bacon ', description: '', price: '$9.99', image: '' },
  { name: 'French Fries with Nacho Cheese & Bacon ', description: '', price: '$9.99', image: '' },
  { name: 'Broccoli Dyna-Bites with Cheddar Cheese (9) ', description: '', price: '$7.99', image: '' },
];

export const StickRolls = [
  { name: 'Breadsticks ', description: '', price: '$7.99', image: '' },
  { name: 'Cheesy Breadsticks ', description: '', price: '$9.99', image: '' },
  { name: 'Pepperoni Cheesy Bread Sticks ', description: '', price: '$10.99', image: '' },
  { name: 'Pepperoni Rolls (8) ', description: 'Server with marinara', price: '$14.99', image: '' },
  { name: 'Sausage Rolls (8) ', description: 'Server with marinara', price: '$14.99', image: '' },
  { name: 'Steak Rolls (8) ', description: 'Server with marinara', price: '$14.99', image: '' },
  { name: 'Chicken Rolls (8) ', description: 'Server with marinara', price: '$14.99', image: '' },
  { name: 'Buffalo Chicken Rolls (8) ', description: 'Server with ranch', price: '$14.99', image: '' },
  { name: 'Spinach & Feta Rolls (8) ', description: 'Server with marinara', price: '$14.99', image: '' },
];

export const wings = [
  {
    name: 'Fresh Wings  ',
    description: 'Choice of wing flavor with blue cheese dipping sauce.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Breaded Wings Dings ',
    description: 'Choice of wing flavor with bleu cheese dipping sauce.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Boneless Wings ',
    description: 'Choice of wing flavor with blue cheese dipping sauce.',
    price: '$10.99+',
    image: '',
  },
];

export const salads = [
  { name: 'Garden Salad ', description: '', price: '$6.99+', image: '' },

  { name: 'Garden Salad with Cheese ', description: '', price: '$7.99+', image: '' },

  {
    name: 'Chicken Salad ',
    description: 'Grilled chicken, fries, cheese, and no onion.',
    price: '$15.99+',
    image: '',
  },
  {
    name: 'Popcorn Chicken Salad ',
    description: 'Popcorn chicken, fries, cheese, and no onion.',
    price: '$15.99+',
    image: '',
  },
  {
    name: 'Chicken Tender Salad ',
    description: 'Chicken tenders, fries, cheese, and no onion.',
    price: '$15.99+',
    image: '',
  },
  {
    name: 'Steak & Chicken Salad ',
    description: 'Grilled chicken, steak, mushroom, fries, and cheese.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Buffalo Chicken Salad ',
    description: 'Buffalo chicken, fries, cheese, and no onion.',
    price: '$16.99+',
    image: '',
  },
  {
    name: 'Tuscan Chicken Salad ',
    description: 'Grilled chicken, roasted red pepper, artichoke hearts, red onion, and feta cheese.',
    price: '$16.99+',
    image: '',
  },
  { name: 'Steak Salad ', description: 'Steak, fries, onion, mushroom, and cheese.', price: '$15.99+', image: '' },
  {
    name: 'Gyro Salad ',
    description: 'Gyro meat, pita bread, black olives, red onion, pepperoncini, and feta cheese.',
    price: '$15.99+',
    image: '',
  },
  { name: 'Seafood Salad ', description: 'Shrimp, crab meat, red onion, and cheese.', price: '$15.99+', image: '' },
  {
    name: 'Antipasta Salad ',
    description: 'Ham, capicola, salami, pepperoni, red onion, hot pepper, and cheese.',
    price: '$15.99+',
    image: '',
  },
  { name: "Chef's Salad ", description: 'Ham, turkey, onion, cheese, and cheddar.', price: '$15.99+', image: '' },
  {
    name: 'Greek Salad ',
    description: 'Black olives, red onion, pepperoncini, and feta.',
    price: '$13.99+',
    image: '',
  },
  {
    name: 'Mediterranean Salad ',
    description: 'Roasted red pepper, artichoke hearts, black olives, pepperoncini, and feta cheese.',
    price: '$15.99+',
    image: '',
  },
  {
    name: 'Caesar Salad ',
    description: 'Tomato, croutons, parmesan cheese, and Caesar dressing.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Caesar Salad with Grilled Chicken ',
    description: 'Tomato, croutons, parmesan cheese, and Caesar dressing.',
    price: '$15.99+',
    image: '',
  },
  {
    name: 'Caesar Salad with Breaded Chicken ',
    description: 'Tomato, croutons, parmesan cheese, and Caesar dressing.',
    price: '$15.99+',
    image: '',
  },
];

export const createYourOwn = [
  {
    name: 'Create Your Own Pizza ',
    description: '',
    price: '$9.99+',
    image: '',
  },
];

export const gourmetPizzas = [
  {
    name: 'Spinach & Feta Pizza ',
    description: 'Spinach, tomato, red onion, mushroom, feta, mozzarella & provolone, and Romano with a white sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Veggie Pizza ',
    description:
      'Fresh garlic, spinach, tomato, black olive, mushroom, red onion, green pepper, and mozzarella & provolone blend with a white sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Mediterranean Pizza ',
    description: 'Artichoke hearts, tomato, roasted red pepper,black olive, mozzarella & provolone and feta with a white sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Spinach & Chicken Pizza ',
    description: 'Spinach, grilled chicken, ranch sauce, tomato, red onion, mozzarella, and provolone with a white sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Chicken Bacon Pizza ',
    description: 'Grilled chicken, bacon, tomato, spinach, cheese, and white sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Tuscan Chicken Pizza (Ranch Sauce) ',
    description: 'Grilled chicken, roasted red peppers, artichoke hearts, spinach, cheese, and feta.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Broccoli with Chicken Pizza ',
    description: 'White pizza with broccoli, grilled chicken, tomatoes, provolone, mozzarella, and cheddar.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Buffalo Chicken Pizza ',
    description: 'Buffalo sauce, grilled chicken, Monterey Jack , cheddar, blended cheeses.buffalo fries',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Hot Chicken Pizza ',
    description: 'Special hot sauce, breaded chicken, cheese, cheddar, and buffalo fries.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Steak Pizza ',
    description: 'Steak, mushroom, red onion, red pepper, mozzarella, and provolone with white sause',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Steak Ranchero Pizza ',
    description: 'Steak, green pepper, tomato, bacon, and buffalo fries topped with mozzarella & provolone. Made with a ranch sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Greek Pizza ',
    description: 'Gyro meat, tomato, red onion, black olive, feta, mozzarella, and provolone with a white sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Supreme Pizza ',
    description: 'Pepperoni, sausage, bacon, mushroom, green pepper, red onion, black olive, mozzarella, and provolone with a red sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Seafood Pizza ',
    description: 'Shrimp, crab meat, mushroom, red onion, tomato, mozzarella, provolone, and fontinella with a white sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Hawaiian Pizza ',
    description: 'Pineapple, bacon, ham, mozzarella, provolone, and gouda with a red sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'BBQ Hawaiian Pizza  ',
    description: 'Grilled chicken, bacon, pineapple, mozzarella, provolone, and gouda with a BBQ sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: "Meat Lover's Pizza ",
    description: 'Ham, salami, capicola, pepperoni, sausage, bacon, mozzarella, and provolone with a red sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Ultimate Meat Pizza ',
    description: 'Ham, salami, capicola, gyro meat, pepperoni, sausage, bacon, and cheese with red sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Cheeseburger Pizza ',
    description: 'White sauce, burger patty, cheese, cheddar, finished with lettuce, tomato, onion, pickle, mayo, and ketchup.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Bacon Burger Pizza ',
    description: 'White sauce, burger patty, bacon, cheese, cheddar, finished with lettuce, tomato, onion, pickle, mayo, and ketchup.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Chicken Ranchero Pizza ',
    description: 'Chicken, green pepper, tomato, bacon, and buffalo fries topped with mozzarella & provolone. Made with a ranch sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'BBQ Chicken Pizza ',
    description: 'Chicken, mushroom, green pepper, red onion, mozzarella, provolone and gouda with a BBQ sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Triple Pepperoni Pizza ',
    description: 'Three times the pepperoni and topped with extra cheese. Made with a red sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Feta & Ricotta Pizza ',
    description: 'Feta, ricotta, mozzarella & provolone blend, bacon, spinach, tomato with a white sauce.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Chicken Alfredo Pizza ',
    description: 'Creamy Alfredo sauce, grilled chicken, bacon, mozarella & provolone blend, cheddar, and Parmesan.',
    price: '$12.99+',
    image: '',
  },
  {
    name: 'Seafood Alfredo Pizza ',
    description: 'Creamy Alfredo sauce, crab meat, shrimp, mozzarella & provolone blend, cheddar, and Parmesan.',
    price: '$12.99+',
    image: '',
  },
  {
    name: "Cheese Lover's Pizza  ",
    description: 'Mozzarella, provolone, feta, ricotta, Romano, and cheddar cheeses with a red sauce.',
    price: '$12.99+',
    image: '',
  },
];

export const calzones = [
  { name: 'Chicken Ranch ', description: 'Grilled chicken, ranch, bacon, and tomato.', price: '$17.99+', image: '' },
  { name: 'Buffalo Chicken ', description: 'Hot sauce.', price: '$17.99+', image: '' },
  { name: 'Pepperoni ', description: '', price: '$17.99+ ', image: '' },
  { name: 'Sausage ', description: '', price: '$17.99+', image: '' },
  { name: 'Italian ', description: 'Pepperoni, ham, capicola, and salami.', price: '$17.99+', image: '' },
  { name: 'Steak ', description: 'Mushrooms, onions, and green peppers.', price: '$17.99+', image: '' },
  { name: 'Seafood ', description: 'Crab meat and shrimp with white sauce.', price: '$17.99+', image: '' },
  { name: 'Spinach & Feta ', description: 'White sauce.', price: '$17.99+', image: '' },
  { name: 'Meatball ', description: '', price: '$17.99+ ', image: '' },
  { name: 'Broccoli Chicken ', description: 'Chicken, broccoli, and white sauce.', price: '$17.99+', image: '' },
  {
    name: 'Veggie ',
    description: 'Green peppers, onions, mushrooms, black olives, and spinach.',
    price: '$17.99+',
    image: '',
  },
];

export const strombolis = [
  { name: 'Chicken ', description: '', price: '$17.99+ ', image: '' },
  { name: 'Pepperoni ', description: '', price: '$17.99+ ', image: '' },
  { name: 'Spinach & Feta ', description: '', price: '$17.99+ ', image: '' },
  { name: 'Italian ', description: 'Pepperoni, ham, capicola, and salami.', price: '$17.99+', image: '' },
  { name: 'Steak ', description: 'Mushrooms.', price: '$17.99+', image: '' },
  { name: 'Seafood ', description: 'Crab meat and shrimp', price: '$17.99+', image: '' },
  { name: 'Buffalo Chicken ', description: 'Hot.', price: '$17.99+', image: '' },
  { name: 'Sausage ', description: '', price: '$17.99+', image: '' },
];

export const wedgies = [
  { name: 'Chicken ', description: '', price: '$17.99+ ', image: '' },
  { name: 'Buffalo Chicken ', description: '', price: '$17.99+ ', image: '' },
  {
    name: 'Veggie ',
    description: 'Green peppers, mushrooms, spinach, and black olives.',
    price: '$17.99+',
    image: '',
  },
  { name: 'Italian ', description: 'Pepperoni, ham, capicola, and salami.', price: '$17.99+', image: '' },
  { name: 'Sausage ', description: '', price: '$17.99+', image: '' },
  { name: 'Steak ', description: 'Mushrooms.', price: '$17.99+', image: '' },
  { name: 'Seafood ', description: 'Crab meat and shrimp.', price: '$17.99+', image: '' },
  { name: 'Spinach & Feta ', description: '', price: '$17.99+ ', image: '' },
  {
    name: 'Greek ',
    description: 'Gyro meat, black olives, feta cheese, and white sauce.',
    price: '$17.99+',
    image: '',
  },
  { name: 'Broccoli Chicken ', description: 'Chicken and broccoli', price: '$17.99+', image: '' },
];

export const pastaDinners = [
  { name: 'Spaghetti with Marinara Sauce ', description: '', price: '$12.99+ ', image: '' },
  { name: 'Spaghetti with Meatballs ', description: '', price: '$15.99+ ', image: '' },
  { name: 'Spaghetti with Hot Sausage ', description: '', price: '$15.99+ ', image: '' },
  { name: 'Baked Ziti ', description: 'Penne pasta with marinara sauce and cheese.', price: '$13.99+', image: '' },
  {
    name: 'Greek Dinner ',
    description: 'Fettuccine with gyro meat, roasted red peppers, black olives, tomato, and feta cheese with white sauce.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Chicken & Roasted Red Peppers ',
    description: 'Served with mushrooms in a homemade garlic butter sauce over penne.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Tuscan Chicken Garlic ',
    description: 'Sauce, chicken, artichoke hearts, spinach, and roasted red peppers over fettuccine.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Mediterranean Pasta ',
    description:
      'Roasted red peppers, artichoke hearts, mushrooms, red onions, and tomatoes in a homemade garlic butter sauce over penne, topped with feta cheese.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Seafood Dinner ',
    description: 'Shrimp, crab meat, and mushrooms with Alfredo sauce. Served over penne.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Chicken Carbonara ',
    description: 'Grilled chicken, bacon, and mushrooms with Alfredo sauce. Served over fettuccine chicken.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Chicken Parmesan Dinner ',
    description: 'Served with melted provolone over spaghetti with marinara sauce.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Eggplant Parmesan Dinner ',
    description: 'Served with melted provolone over spaghetti with marinara sauce.',
    price: '$17.99+',
    image: '',
  },
  { name: 'Fettuccine Alfredo ', description: 'Alfredo sauce served with fettuccine.', price: '$13.99+', image: '' },
  { name: 'Fettuccine Alfredo Chicken ', description: 'Chicken with Alfredo sauce.', price: '$17.99+', image: '' },
  {
    name: 'Fettuccine Broccoli & Chicken Alfredo ',
    description: 'Broccoli and chicken with Alfredo sauce.',
    price: '$17.99+',
    image: '',
  },
  {
    name: 'Pierogies ',
    description: 'Delicious potato-filled pockets with butter & onion in garlic sauce.',
    price: '$14.99+',
    image: '',
  },
  {
    name: 'Meat Pasta ',
    description: 'Spaghetti with meatballs, hot sausage, and marinara sauce.',
    price: '$17.99+',
    image: '',
  },
  { name: 'Beef Ravioli ', description: 'Served in marinara sauce, topped with cheese.', price: '$14.99+', image: '' },
  {
    name: 'Cheese Ravioli ',
    description: 'Served in marinara sauce, topped with cheese.',
    price: '$14.99+',
    image: '',
  },
];

export const hoagies = [
  { name: 'Steak Hoagie ', description: 'Steak, mushroom, red onion, provolone, lettuce, and tomato.', price: '$9.99+', image: '' },
  {
    name: 'Italian Sub Hoagie ',
    description: 'Capicola, ham, salami, provolone, lettuce, tomato, red onion, and Italian dressing.',
    price: '$9.99+',
    image: '',
  },
  {
    name: 'Turkey Sub Hoagie ',
    description: 'Turkey, provolone cheese, lettuce, tomatoes, red onions, and Italian dressing.',
    price: '$9.99+',
    image: '',
  },
  { name: 'Ham & Cheese Hoagie ', description: 'Ham, provolone, lettuce, tomato, red onion, and Italian dressing.', price: '$9.99+', image: '' },
  { name: 'Meatball Hoagie ', description: 'Meatballs with marinara sauce & provolone.', price: '$9.99+', image: '' },
  { name: 'Chicken Parmesan Hoagie ', description: 'Breaded chicken with marinara sauce & provolone.', price: '$9.99+', image: '' },
  { name: 'Grilled Chicken Hoagie ', description: 'Grilled chicken, provolone cheese, lettuce, tomato, and red onion.', price: '$9.99+', image: '' },
  { name: 'Crispy Chicken Hoagie ', description: 'Crispy chicken, provolone cheese, lettuce, tomato, and red onion.', price: '$9.99+', image: '' },
  { name: 'Buffalo Chicken Hoagie ', description: 'Hot sauce, breaded chicken, provolone, buffalo fries, and ranch.', price: '$9.99+', image: '' },
  { name: 'Fish Hoagie ', description: 'Cod fillet, provolone, lettuce, tomato, red onion, and tartar sauce.', price: '$9.99+', image: '' },
  {
    name: 'Veggie Hoagie ',
    description:
      'Brush of garlic butter, spinach, mushrooms, red onions, green peppers, black olives, provolone cheese, lettuce, tomatoes, onions, and Italian dressing.',
    price: '$9.99+',
    image: '',
  },
  {
    name: 'Seafood Hoagie ',
    description: 'Garlic Sauce, shrimp, crab meat, provolone, lettuce, tomato, red onion, and Italian dressing.',
    price: '$9.99+',
    image: '',
  },
  { name: 'Sausage Sub ', description: 'Hot sausage with marinara sauce and extra cheese.', price: '$9.99+', image: '' },
  { name: 'Eggplant Parmesan Sub ', description: 'Breaded eggplant with marina sauce and extra cheese.', price: '$9.99+', image: '' },
];

export const gourmetHoagies = [
  {
    name: 'Deluxe Steak Hoagie ',
    description: 'Steak, mushrooms, red onions, green peppers, hot peppers, provolone, lettuce, and tomatoes.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Deluxe Italian Hoagie ',
    description: 'Capicola, ham, salami, pepperoni, sausage, lettuce, tomatoes, red onions, and Italian dressing.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Steak Ranchero Hoagie ',
    description: 'Steak, ranch, bacon, and buffalo fries.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Chicken Ranchero Hoagie ',
    description: 'Grilled chicken, ranch, bacon, and buffalo fries.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Medi Hoagie ',
    description: 'Garlic sauce, roasted red peppers, artichoke hearts, olives, feta, lettuce, tomatoes, red onion, and dressing.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Tuscan Chicken Hoagie ',
    description: 'Grilled chicken, artichoke hearts, roasted red peppers, tomatoes, provolone, and feta cheese, with garlic sauce.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'BBQ Chicken Hoagie ',
    description: 'Grilled chicken, french fries, cheddar, and provolone, brushed with BBQ sauce.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Club Hoagie ',
    description: 'Turkey, ham, bacon, provolone & cheddar cheese, lettuce, tomatoes, and red onion.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Grilled Chicken Deluxe ',
    description: 'Grilled chicken, bacon, extra cheese, lettuce, tomato, and red onion.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Crispy Chicken Deluxe ',
    description: 'Crispy chicken, bacon, extra cheese, lettuce, tomato, and red onion.',
    price: '$10.99+',
    image: '',
  },
];
export const gyros = [
  { name: 'Rotisserie Gyro ', description: '', price: '$9.99+', image: '' },
  { name: 'Chicken Gyro ', description: '', price: '$9.99+', image: '' },
];

export const wraps = [
  { name: 'Rotisserie Gyro Wrap ', description: '', price: '$11.99+', image: '' },
  { name: 'Grilled Chicken Wrap ', description: '', price: '$11.99+', image: '' },
  { name: 'Crispy Chicken Wrap ', description: '', price: '$11.99+', image: '' },
  {
    name: 'Buffalo Chicken Wrap ',
    description: 'Spicy breaded chicken with ranch on the side.',
    price: '$11.99+',
    image: '',
  },
];

export const dressings = [
  { name: 'Italian Dressing ', description: '', price: '$1', image: '' },
  { name: 'Lite Italian Dressing ', description: '', price: '$1', image: '' },
  { name: 'Ranch Dressing ', description: '', price: '$1', image: '' },
  { name: 'Fat Free Ranch Dressing ', description: '', price: '$1', image: '' },
  { name: 'Blue Cheese Dressing ', description: '', price: '$1', image: '' },
  { name: 'French Dressing ', description: '', price: '$1', image: '' },
  { name: 'Honey Dijon Dressing ', description: '', price: '$1', image: '' },
  { name: 'Greek Dressing ', description: '', price: '$1', image: '' },
  { name: 'Gyro Sauce ', description: '', price: '$1', image: '' },
  { name: 'Balsamic Vinaigrette ', description: '', price: '$1', image: '' },
  { name: 'Hot Sauce ', description: '', price: '$1', image: '' },
  { name: 'Mild Sauce ', description: '', price: '$1', image: '' },
  { name: 'Buffalo Sauce ', description: '', price: '$1', image: '' },
  { name: 'BBQ Sauce ', description: '', price: '$1', image: '' },
  { name: 'Garlic Parmesan Sauce ', description: '', price: '$1', image: '' },
];

export const desserts = [
  { name: 'Tiramisu ', description: '', price: '$5.99', image: '' },
  { name: 'Chocolate Dipped Cannoli (2 pcs.) ', description: '', price: '$6.99', image: '' },
  { name: 'Cheesecake ', description: 'Plain, Strawberry, or Oreo', price: '$5.99', image: '' },
  { name: 'Baklava (Pistachio, 4 pcs.) ', description: '', price: '$6.99', image: '' },
];

export const burgers = [
  {
    name: 'Cheeseburger ',
    description: 'American cheese topped with lettuce, tomatoes, red onions, ketchup, mayo, and pickle.',
    price: '$9.99+',
    image: '',
  },
  {
    name: 'Bacon Cheeseburger ',
    description: 'Bacon and American cheese topped with lettuce, tomatoes, ketchup, red onions, mayo, and pickle.',
    price: '$10.99+',
    image: '',
  },
  {
    name: 'Hamburger ',
    description: 'Topped with lettuce, ketchup, tomatoes, red onions, mayo, and pickle.',
    price: '$9.99+',
    image: '',
  },
  {
    name: 'Breaded Chicken Burger ',
    description: 'Provolone topped with lettuce, tomatoes, mayo, and red onions.',
    price: '$9.99+',
    image: '',
  },
  {
    name: 'Buffalo Chicken Burger ',
    description: 'Breaded chicken, provolone, and hot sauce topped with lettuce, tomatoes, pickles, and red onions.',
    price: '$10.99+',
    image: '',
  },
];

export const beverages = [
  {
    name: '2-Liter Soda ',
    description: 'Pepsi, Diet Pepsi, Cherry Pepsi, Root Beer, Mountain Dew, Mist Twist, Ginger Ale.',
    price: '$4.99',
    image: '',
  },
  {
    name: 'Bottled Soda (20 oz.) ',
    description: 'Pepsi, Diet Pepsi, Cherry Pepsi, Root Beer, Mountain Dew, Mist Twist, Ginger Ale.',
    price: '$2.99',
    image: '',
  },
];
