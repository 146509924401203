import React from 'react';
import logo from '../assets/Pizza_Ephesus_logo.png';

export default function Header() {
  return (
    // <div className=' bg-white drop-shadow-[0_5px_15px_rgba(0,0,0,0.25)] mb-10'>
    <div className="">
      <nav className="2xl:container 2xl:mx-auto sm:py-6 sm:px-7 py-5 px-4">
        {/* For large and Medium-sized Screen */}
        <div className="lg:max-w-[1480px] md:max-w-[744px] max-w-[375px] w-full mx-auto grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2">
          <div className="hidden sm:flex flex-row items-center space-x-6 justify-start">
            <svg
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z"
                stroke="#1F2937"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <svg
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 cursor-pointer"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                stroke="#1F2937"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.0002 11.3701C16.1236 12.2023 15.9815 13.0523 15.594 13.7991C15.2065 14.5459 14.5933 15.1515 13.8418 15.5297C13.0903 15.908 12.2386 16.0397 11.408 15.906C10.5773 15.7723 9.80996 15.3801 9.21503 14.7852C8.62011 14.1903 8.22793 13.4229 8.09426 12.5923C7.9606 11.7616 8.09226 10.91 8.47052 10.1584C8.84878 9.40691 9.45438 8.7938 10.2012 8.4063C10.948 8.0188 11.7979 7.87665 12.6302 8.00006C13.4791 8.12594 14.265 8.52152 14.8719 9.12836C15.4787 9.73521 15.8743 10.5211 16.0002 11.3701Z"
                stroke="#1F2937"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M17.5 6.5H17.51" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="flex space-x-3 items-center w-50 h-50 justify-center">
            <img src={logo} alt="logo" className="object-cover h-30" />
          </div>
          <div className="flex items-center justify-end">
            <button
              onClick={() => window.open('https://form.jotform.com/222508451528152')}
              className="rounded-md flex mr-5 font-ephesus space-x-2 w-28 h-10 font-normal text-sm leading-3 text-gray-700 bg-white border border-gray-700 focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
            >
              Join our team!
            </button>
            <button
              onClick={() => window.open('https://orderonline.granburyrs.com/slice/index.html?accountId=434', '_blank', 'noopener,noreferrer')}
              className="rounded-md flex space-x-2 w-48 h-10 font-ephesus text-lg leading-3 text-white bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 md:text-sm focus:ring-gray-700 focus:bg-gray-600 hover:bg-gray-600 duration-150 justify-center items-center"
            >
              ORDER ONLINE
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
}
