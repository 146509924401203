import React from 'react';
import {
  appetizers,
  beverages,
  burgers,
  calzones,
  createYourOwn,
  desserts,
  dressings,
  gourmetHoagies,
  gourmetPizzas,
  gyros,
  hoagies,
  pastaDinners,
  pickUpSpecials,
  salads,
  specials,
  StickRolls,
  strombolis,
  wings,
  wraps,
} from './constants';
const AccordionMenu = React.lazy(() => import('./AccordionMenu'));

export default function Menu() {
  return (
    <div className=" border-t-2 border-orange-200">
      <div>
        <div className="relative flex flex-col items-center justify-center sm:px-0 px-6 z-20 pb-32">
          <h2 className="text-black  text-4xl leading-normal w-full text-center font-ephesus p-10">MENU</h2>

          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">-Pick Up Specials-</summary>
            <AccordionMenu products={pickUpSpecials} />
          </details>

          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">-Specials-</summary>
            <AccordionMenu products={specials} />
          </details>

          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Appetizers</summary>
            <AccordionMenu products={appetizers} />
          </details>

          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">STICKS & ROLLS</summary>
            <AccordionMenu products={StickRolls} />
          </details>

          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Wings</summary>
            <AccordionMenu products={wings} wingsMenu={true} />
          </details>

          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Salads</summary>
            <AccordionMenu products={salads} saladMenu={true} />
          </details>

          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Create Your Own Pizza</summary>
            <AccordionMenu products={createYourOwn} cyopMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Gourmet Pizza</summary>
            <AccordionMenu products={gourmetPizzas} gourmetPizzaMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Calzones</summary>
            <AccordionMenu products={calzones} calzoneMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Strombolis</summary>
            <AccordionMenu products={strombolis} stromboliMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Pasta Dinners</summary>
            <AccordionMenu products={pastaDinners} pastaMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Oven Baked Hoagies</summary>
            <AccordionMenu products={hoagies} ovenBakedMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Oven Baked Gourmet Hoagies</summary>
            <AccordionMenu products={gourmetHoagies} gOvenBakedMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Gyros</summary>
            <AccordionMenu products={gyros} gyroMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Wraps</summary>
            <AccordionMenu products={wraps} wrapMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Burgers</summary>
            <AccordionMenu products={burgers} burgerMenu={true} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Dressings & Sauces</summary>
            <AccordionMenu products={dressings} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Desserts</summary>
            <AccordionMenu products={desserts} />
          </details>
          <details className="w-full text-left bg-orange-500 rounded-md border-orange-900 border mb-1">
            <summary className="text-black  text-xl leading-normal w-full font-ephesus p-5 cursor-pointer">Beverages</summary>
            <AccordionMenu products={beverages} />
          </details>
        </div>
      </div>
    </div>
  );
}
