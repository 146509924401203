import React from 'react';
import './App.css';
import Header from './components/Header';
import SubHeader from './components/SubHeader';
import Menu from './components/Menu';
const Address = React.lazy(() => import('./components/Address.js'));

function App() {
  return (
    <div className="bg-[url('./assets/pizza_4_stagioni_archite_01.svg')] bg-[length:200px_100px] bg-repeat bg-opacity-25">
      {/* <div className=""> */}
      <div className="bg-opacity-90 bg-white">
        <div className="lg:max-w-[1480px] md:max-w-[744px] max-w-[375px] w-full mx-auto">
          <Header />
          <SubHeader />
          <Menu />
          <Address />
        </div>
      </div>
    </div>
  );
}

export default App;
