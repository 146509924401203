import React from 'react';
function SubHeader() {
  return (
    <div className="lg:max-w-[1480px] md:max-w-[744px] max-w-[375px] w-full mx-auto grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-2  p-5 border-t-2 border-orange-200">
      <div className="flex content-center ph:justify-center">
        <div className="ph:mb-4">
          <h3 className="font-ephesus leading-4 text-gray-800 text-base md:text-sm ph:text-center ">Phone</h3>
          <div className="flex items-end mt-1">
            <h2 className="text-gray-700  text-2xl leading-normal font-ephesus md:text-sm">(412) 766-7660</h2>
          </div>
        </div>
      </div>
      <div className="flex content-start justify-center">
        <div className="">
          <h3 className="font-ephesus leading-4 text-gray-800 ph:text-lg text-center">Working Hours</h3>
          <div className="flex items-end mt-1">
            <h2 className="text-gray-700 md:text-sm text-lg leading-normal font-ephesus ph:text-center ph:text-sm ">
              Sunday-Thursday from 11:00 am to 10:00 pm
            </h2>
          </div>
          <div className="flex items-end mt-1">
            <h2 className="text-gray-700 md:text-sm text-lg leading-normal font-ephesus ph:text-sm ph:text-center">
              Friday and Saturday from 11:00 am to 11:00 pm
            </h2>
          </div>
        </div>
      </div>
      <div className="flex content-start justify-end ph:justify-center">
        <div className="">
          <h3 className="font-ephesus leading-4 text-gray-800 ph:text-lg text-center">Address</h3>
          <h3 className="font-ephesus leading-4 text-gray-800 md:text-sm text-base ph:text-center ph:text-sm ">501 Lincoln Avenue Bellevue</h3>
          <div className="flex items-end mt-1">
            <h2 className="text-gray-700  text-2xl leading-normal md:text-sm w-full text-right font-ephesus ph:text-center ph:text-sm ">PA 15202</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubHeader;
